import React from 'react';
import { Link } from 'gatsby';

import Navbar from '../components/Navbar';

import { Helmet } from 'react-helmet';
import Footer from '../components/Footer';

const ErrorPage = () => {
  return (
    <div className="min-h-screen">
      <Helmet>
        <meta charSet="utf-8" />
        <title>404 not found</title>
      </Helmet>
      <Navbar />
      <main className="mb-36 mt-36 h-2/3">
        <section>
          <div className="flex flex-col items-center justify-center text-center text-black">
            <h1 className="text-center text-3xl font-semibold text-red-600 lg:text-8xl">
              404
            </h1>
            <h3 className="py-2 lg:py-4 text-center text-xl font-bold lg:text-6xl">
              Page introuvable
            </h3>
            <h3 className="text-center text-base text-[#181818] lg:text-xl">
              Veuillez nous excuser, la page que vous avez demandée est
              introuvable
            </h3>
            <div className="flex mt-6 items-center gap-12 sm:gap-20 ">
              <Link
                to="/"
                className="text-base font-medium px-5 py-2 rounded-md shadow-sm  border-gray-200 text-white bg-secondarycolor hover:bg-secondarycolor-light"
              >
                Home
              </Link>{' '}
              <Link
                to="/contact"
                className="text-base font-medium px-5 py-2 rounded-md shadow-sm  border-gray-200 text-white bg-secondarycolor hover:bg-secondarycolor-light"
              >
                Contact
              </Link>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default ErrorPage;
